<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        In the second part of this experiment, you will be required to calculate the rate of
        conversion of reactions carried out in two vials based on the TLC plate you prepared and
        ImageJ analysis. Below is some fictional data from such an analysis. Use this information to
        determine the rate of conversion for the two vials. Express your answers to 3 significant
        figures.
      </p>

      <v-simple-table class="mb-5 pl-6" style="max-width: 341px">
        <thead>
          <tr>
            <th style="font-size: 15px">Reaction</th>
            <th style="font-size: 15px">Alcohol Spot Intensity</th>
            <th style="font-size: 15px">Ester Spot Intensity</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Vial 1</td>
            <td>
              <number-value :value="alcohol1" />
            </td>
            <td>
              <number-value :value="ester1" />
            </td>
          </tr>

          <tr>
            <td>Vial 2</td>
            <td>
              <number-value :value="alcohol2" />
            </td>
            <td>
              <number-value :value="ester2" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <calculation-input
        v-model="inputs.inputA"
        prepend-text="$\text{a) Vial 1:}$"
        append-text="$\%$"
        :disabled="!allowEditing"
        class="mb-3"
      />

      <calculation-input
        v-model="inputs.inputB"
        prepend-text="$\text{b) Vial 2:}$"
        append-text="$\%$"
        :disabled="!allowEditing"
        class="mb-0"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'ChemUCI_51LPL6_Q7',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        inputA: null,
        inputB: null,
      },
    };
  },
  computed: {
    alcohol1() {
      return this.taskState.getValueBySymbol('alcohol1').content;
    },
    alcohol2() {
      return this.taskState.getValueBySymbol('alcohol2').content;
    },
    ester1() {
      return this.taskState.getValueBySymbol('ester1').content;
    },
    ester2() {
      return this.taskState.getValueBySymbol('ester2').content;
    },
  },
};
</script>
